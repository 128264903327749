import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/index.vue'),
    meta: {
      title: '视频会员'
    }
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('../views/recharge.vue'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/signergys',
    name: 'signergys',
    component: () => import('../views/sj/signergys.vue'),
    meta: {
      title: '恭喜获得'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue'),
    meta: {
      title: '会员充值订单'
    }
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/service.vue'),
    meta: {
      title: '客服'
    }
  }
  ,
  {
    path: '/bigdatapay',
    name: 'Bigdatapay',
    component: () => import('../views/bigdatapay.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/queryorder',
    name: 'queryorder',
    component: () => import('../views/queryorder.vue'),
    meta: {
      title: '充值订单查询'
    }
  },
]
const router = new VueRouter({
  // mode: 'history',
  base: process.env.VUE_APP_PATH,
  routes
})
export default router